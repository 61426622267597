h1 {
  margin-bottom: 2rem;
  }

.navbar-nav {
  margin: auto;
}

.Home {
  background-color: #343a40 !important;
  padding-bottom: 20px;
  border-radius: 10px;
  margin-bottom: 40px;
}

.Container {
  padding-left: 20px; 
  padding-right: 20px;
}


@media only screen and (max-width: 1000px) {
  .Container {
    padding: 0;
  }

  .responses {
    padding-left: 20px; 
    padding-right: 20px;
  }
}