.App {
  text-align: center;
  background-color: #282c34;
  min-height: 100vh;
  color: white;
  padding: 20px 100px;
}


@media only screen and (max-width: 1000px) {
  .App {
    padding: 10px 20px;
  }
}